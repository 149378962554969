import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-ag-grid-wrapper',
  standalone: true,
  imports: [CommonModule, AgGridModule],
  templateUrl: './ag-grid-wrapper.component.html',
  styleUrls: ['./ag-grid-wrapper.component.scss']
})
export class AgGridWrapperComponent {

  @Input() data: any[];
  @Input() columns: ColDef[];
  @Input() defaultCol: ColDef;
  @Input() rowSelectionMode: 'single' | 'multiple' | undefined = undefined;
  @Output() ready: EventEmitter<GridReadyEvent> = new EventEmitter<GridReadyEvent>();
  @Output() selection: EventEmitter<RowSelectedEvent> = new EventEmitter<RowSelectedEvent>();

  loader: any = LoaderComponent;
  loadingOverlayComponentParams: any = {
    loadingMessage: "One moment please...",
  };

  paginationPageSize = 15;
  paginationPageSizeSelector = [15, 50, 100];
  defaultColDef: ColDef = {
    filter: true,
    sortable: true,
    minWidth: 100,
  };

  onGridReady(params: GridReadyEvent) {
    this.ready.emit(params);
  }

  onRowSelected(event: RowSelectedEvent) {
    this.selection.emit(event)
  }

}
