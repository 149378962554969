import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'btn-cell-renderer',
  standalone: false,
  templateUrl: './btn-cell-renderer.html',
  styleUrls: ['./btn-cell-renderer.scss']
})
export class BtnCellRenderer implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  public params: any;
  agInit(params: any): void {
    this.params = params;
  }

  onEditClick(): void {
    this.params.onEdit(this.params.data);
  }

  onViewClick(): void {
    this.params.onView(this.params.data);
  }
  onDuplicateClick(): void {
    this.params.onDuplicate(this.params.data);
  }

  onDeleteClick(): void {
    this.params.onDelete(this.params.data);
  }
  onChangeAvailabilityClick(): void {
    this.params.onChangeAvailability(this.params.data);
  }

  onDisableClick(): void {
    this.params.onDisable(this.params.data);
  }

  onConfirmationClick(): void {
    this.params.getValue().onConfirmation(this.params.getValue());
  }
}
