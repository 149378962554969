import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ISelect } from '../mat-multiselect.component';

@Component({
    selector: 'app-mat-select-all',
    templateUrl: "./mat-select-all.component.html",
    styleUrls: ['./mat-select-all.component.scss'],
    imports: [CommonModule, MatCheckboxModule]
})
export class MatSelectAllComponent {
  @Input() model: FormControl;
  @Input() values: ISelect[] = [];
  @Input() text = 'Select All';

  isChecked(): boolean {
    return this.model.value && this.values.length
      && this.model.value.length === this.values.length;
  }

  isIndeterminate(): boolean {
    return this.model.value && this.values.length && this.model.value.length
      && this.model.value.length < this.values.length;
  }

  toggleSelection(change: any): void {
    if (change.checked) {
      this.model.setValue(this.values);
    } else {
      this.model.setValue([]);
    }
  }
}
