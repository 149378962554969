import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-form-status-cell',
  standalone: false,
  template: `
    <app-form-status-list *ngIf="isList" [statuses]="cellValue" />
    <app-form-status *ngIf="!isList" [status]="cellValue" />
    `,
    styleUrls: ['./form-status-cell-renderer.component.scss']
})
export class FormStatusCellRenderer implements ICellRendererAngularComp {
    public cellValue!: any;
    public isList: boolean;

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    this.isList = Array.isArray(this.cellValue)
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.value;
  }
}
