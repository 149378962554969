import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';
import { removeUndefinedFormsAttributes } from '../utils/utils';
import { BaseService } from './base-service';
import { ISearchFilter } from './search.service';

export enum StatusId {
  'Received' = 1,
  'UnderReview' = 2,
  'SentBack' = 3,
  'Approved' = 5,
  'Rejected' = 6,
}
export interface FormSubmissionStatus {
  id: number;
  name: string;
  statusId: StatusId;
  statusText: string;
  waitingForCurrentUser: boolean;
}

export interface FormSubmission<
  UserType extends 'Lender' | 'Provider' | 'Admin' =
    | 'Lender'
    | 'Provider'
    | 'Admin'
> {
  formSubmissionStatus: UserType extends 'Lender'
    ? FormSubmissionStatus
    : FormSubmissionStatus[];
  id: number;
  industry: {
    id: 4;
    name: string;
  };
  intendedDateOfUse: string;
  product: {
    id: number;
    name: string;
  };
  submissionDate: string;
  submissionType: {
    id: number;
    name: string;
  };
  provider: {
    id: number;
    name: string;
  };
  states: {
    id: number;
    name: string;
  }[];
  lenders: {
    name: string;
    isApproved: boolean | null;
  }[];
  documents: [];
  formNo: string;
  previousApprovedFormNo: string;
  administrator: {
    id: number;
    name: string;
  };
  dueDate: string;
  providerFormNo: string;
  canTakeAction: boolean;
}

export interface INavigation{
  previousId: number | null,
  nextId: number | null
}

export interface IFormSubmissionSearchParameters extends ISearchFilter{
  sortColumn?: string,
  sortOrder?: 'asc' | 'desc',
  backUrl?: string,
}

@Injectable({
  providedIn: 'root',
})
export class FormSubmissionsService extends BaseService<FormSubmission> {
  private localStorageKey = 'formSubmission_searchParameters';
  private formSubmissionFiltersForNavigation$: BehaviorSubject<IFormSubmissionSearchParameters>;

  constructor(http: HttpClient) {
    super(http, `formsubmissions`);
    const savedState = localStorage.getItem(this.localStorageKey);
    this.formSubmissionFiltersForNavigation$ = new BehaviorSubject<IFormSubmissionSearchParameters>(
      savedState ? JSON.parse(savedState) : this.getDefaultState()
    );
  }

  private getDefaultState(): IFormSubmissionSearchParameters {
    return {
      sortColumn: 'submissionDate',
      sortOrder: 'desc',
    };
  }

  getFormSubmissionFiltersForNavigation() {
    return this.formSubmissionFiltersForNavigation$.asObservable();
  }

  getFormSubmissionFiltersForNavigationValue(){
    return this.formSubmissionFiltersForNavigation$.getValue();
  }

  setFormSubmissionFiltersForNavigation(newState: Partial<IFormSubmissionSearchParameters>) {
    removeUndefinedFormsAttributes(newState);
    this.formSubmissionFiltersForNavigation$.next(newState);
    localStorage.setItem(this.localStorageKey, JSON.stringify(newState));
  }

  deleteFormSubmissionFiltersForNavigation(){
    localStorage.removeItem(this.localStorageKey);
  }

  uploadDocument(id: string, file: File): Observable<APIResponseModel<any>> {
    let formData = new FormData();
    formData.append(file.name, file);

    return this.http
      .post<APIResponseModel<any>>(
        `${this.baseUrl}/documentupload/${id}`,
        formData
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  getComments(id: any): Observable<APIResponseModel<any[]>> {
    return this.http
      .get<APIResponseModel<any[]>>(`${this.baseUrl}/${id}/comments`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }

  updateStatus(
    id: string,
    action: string,
    data: any
  ): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/${id}/${action}`, data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  updateFormSubmission(
    id: string,
    data: any
  ): Observable<APIResponseModel<any>> {
    return this.http
      .put<APIResponseModel<any>>(`${this.baseUrl}/${id}`, data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  getLenders(formId: number) {
    return this.http
      .get<APIResponseModel<any[]>>(`${this.baseUrl}/${formId}/lenders`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }


  replyComment(
    formId: string,
    commentId: string = '',
    data: any
  ): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/${formId}/comments/${commentId}/reply`, data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  getNavigation(formId: number, filters: any): Observable<APIResponseModel<INavigation>> {
    return this.http
      .get<APIResponseModel<INavigation>>(`${this.baseUrl}/${formId}/navigation`, filters ? { params: {...filters } } : { params: {} })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }

}
