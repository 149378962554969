import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { APIResponseModel } from '../models/api-response-model';
import { BehaviorSubject, Observable, catchError, map, shareReplay, take, tap } from 'rxjs';
import { ORGANIZATION_ID_CLAIM, USER_ID_CLAIM, UserModel } from '../models/user/user.model';
import { AuthService } from '@auth0/auth0-angular';
import { ROLE_CLAIM, Role } from '../models/user/role.model';

export interface IUserNotificationPreference {
  frequency?: 'EndOfDay' | 'OnDemand';
  events?: IEvent[];
}
export interface IEvent {
  event?: 'Modified' | 'ReachedFinalState' | 'Expiring';
  isActive?: boolean;
}

export interface IUserProfile {
  firstName: string,
  lastName: string,
  phoneNumber?: string,
  profileImage?: File
}
@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {

  organizationId$: Observable<number>;
  userRole$: Observable<Role | undefined>;

  private userProfileSubject = new BehaviorSubject<IUserProfile | null>({firstName: '', lastName: ''});
  public userProfile$: Observable<IUserProfile | null> = this.userProfileSubject.asObservable();

  constructor(http: HttpClient, public authService: AuthService) {
    super(http, `users`);

    this.organizationId$ = this.getOrganizationId();
    this.userRole$ = this.getUserRole();
  }

  getUserRole(): Observable<Role | undefined> {
    return this.authService.user$.pipe(
      take(1),
      map(user => {
        return user ? user[ROLE_CLAIM] as Role : undefined;
      }),
      shareReplay(1)
    )
  }


  getOrganizationId(): Observable<number> {
    return this.authService.user$
      .pipe(
        take(1),
        map(user => {
          if (!user) {
            return 0;
          }
          const orgId = user[ORGANIZATION_ID_CLAIM];
          if (!orgId) {
            return 0;
          }
          return orgId;
        }),
        shareReplay(1)
      );
  }

  getUserId(): Observable<number> {
    return this.authService.user$
      .pipe(
        take(1),
        map(user => {
          if (!user) {
            return 0;
          }
          const userId = user[USER_ID_CLAIM];
          if (!userId) {
            return 0;
          }
          return userId;
        }),
        shareReplay(1)
      );
  }
  getUserProfile(): Observable<any> {
    return this.http
    .get<APIResponseModel<IUserProfile>>(
      `${this.baseUrl}/profile`
    ).pipe(
        tap(profile => this.userProfileSubject.next({
          firstName: profile.data.firstName,
          lastName: profile.data.lastName,
          phoneNumber: profile.data.phoneNumber,
        })
      ),
    )
  }

  changeUserAvailability(id: string, enabled: boolean): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/change-availability`, { id, enabled: enabled })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  getPreferences(): Observable<APIResponseModel<IUserNotificationPreference>> {
    return this.http
      .get<APIResponseModel<IUserNotificationPreference>>(
        `${this.baseUrl}/preferences`
      )
  }

  changeUserPreferences(preferences: IUserNotificationPreference): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/preferences`, { ...preferences })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  updateUserProfile(profile: IUserProfile): Observable<APIResponseModel<any>> {

    const formData = new FormData();
    formData.append('firstName', profile.firstName);
    formData.append('lastName', profile.lastName);

    if(profile.phoneNumber)
      formData.append('phoneNumber', profile.phoneNumber);
    if(profile.profileImage)
      formData.append('profileImage', profile.profileImage);

    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/profile`, formData)
      .pipe(
        tap((_)=>this.userProfileSubject.next(profile)),
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  getUserImageProfile(id: string): Observable<APIResponseModel<any>> {
    return this.http
      .get<APIResponseModel<any>>(
        `${this.baseUrl}/${id}/profile-image`
      )
  }

  resetUserPassword(): Observable<APIResponseModel<any>> {
    return this.http
    .post<APIResponseModel<any>>(`${this.baseUrl}/reset-password`, {})
    .pipe(
      catchError((err: HttpErrorResponse) => {
        return this.httpErrorHandler(err, {});
      })
    );
  }
}
