import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISearchResult } from '../modules/shared/components/main-navigation/search.model';
import { ToastService } from './toast.service';

export interface ISearchFilter {
  searchKey?: string;
  lenderIds?: number[];
  status?: number;
  providerOrganizationId?: number;
  administratorId?: number;
  productId?: number;
  industryId?: number;
  fullSubmission?: boolean;
  statesIds?: number[];
  page?: number;
  pageSize?: number;
}

export interface IExportFilter {
  searchFilters: ISearchFilter,
  formSubmission: boolean,
  formConsolidation: boolean,
  mbrRequest: boolean,
  marketingMaterial: boolean,
  ExternalApproval: boolean
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  domain: any;
  baseUrl: string;
  defaultPageSize = 15;

  private filtersSubject = new BehaviorSubject<ISearchFilter>({pageSize: this.defaultPageSize});

  filters$ = this.filtersSubject.asObservable();

  private httpClient: HttpClient;

  constructor(private http: HttpClient, private toastService: ToastService) {
    this.domain =
      environment.baseUrl ?? location.origin.replace('portal', 'api');
    this.baseUrl = [this.domain, 'api', 'search'].join('/');
  }

  generalSearch(formType?: string, filters?: any): Observable<ISearchResult[] | any> {
      const url = formType ? `${this.baseUrl}/${formType}` : this.baseUrl;
      return this.http.post<ISearchResult[] | any>(url, filters);
  }

  exportSearch(exportFilter: IExportFilter) {
    const url = `${this.baseUrl}/export`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    return this.http.post<Blob>(url, { ...exportFilter }, { headers, responseType: 'blob' as 'json' })
      .pipe(
        tap((res: Blob) => {
          const date = new Date();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
          const fileName = `search_result_${month}${day}${year}.xlsx`;

          const fileUrl = URL.createObjectURL(res);
          const a = document.createElement('a');
          a.href = fileUrl;
          a.download = fileName;
          a.click();
          URL.revokeObjectURL(fileUrl);

          this.toastService.success('Documents', 'Were successfully exported');
        })
      );
  }

  updateFilters(newFilters: ISearchFilter) {
    this.filtersSubject.next({ ...this.filtersSubject.value, ...newFilters });
  }

  getFiltersValue(): ISearchFilter{
    return this.filtersSubject.getValue();
  }

}
