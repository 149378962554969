import { Component, Input, OnInit } from '@angular/core';
import { IFormStatusSimplified } from './form-status.interface';
import { FormSubmissionStatus, StatusId } from 'src/app/services/form-submissions.service';

@Component({
  selector: 'app-form-status-list',
  standalone: false,
  templateUrl: './form-status-list.component.html',
  styleUrls: ['./form-status-list.component.scss'],
})
export class FormStatusListComponent implements OnInit {
  @Input() statuses: FormSubmissionStatus[] = [];
  readonly MAX_STATUS = 3;
  groupedStatuses: IFormStatusSimplified[] = [
    {
      statusId: StatusId.Received,
      statusText: 'Received',
      toolTipList: []
    },
    {
      statusId: StatusId.UnderReview,
      statusText: 'Under review',
      toolTipList: []
    },
    {
      statusId: StatusId.Approved,
      statusText: 'Approved',
      toolTipList: []
    },
    {
      statusId: StatusId.Rejected,
      statusText: 'Rejected',
      toolTipList: []
    }
  ];

  ngOnInit(): void {
    this.groupStatuses();
  }

  groupStatuses() {
    this.groupedStatuses[0].toolTipList = this.statuses.filter(
      (status) =>
        status.statusId == StatusId.SentBack ||
        status.statusId == StatusId.Received
    ).map(s => s.name);

    this.groupedStatuses[1].toolTipList = this.statuses.filter(
      (status) =>
        status.statusId == StatusId.UnderReview
    ).map(s => s.name);

    this.groupedStatuses[2].toolTipList = this.statuses.filter(
      (status) =>
        status.statusId == StatusId.Approved
    ).map(s => s.name);

    this.groupedStatuses[3].toolTipList = this.statuses.filter(
      (status) =>
        status.statusId == StatusId.Rejected
    ).map(s => s.name);
  }
}
