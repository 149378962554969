import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
} from '@angular/material/snack-bar';

@Component({
    selector: 'app-toast-message',
    imports: [CommonModule, MatSnackBarModule],
    templateUrl: './toast-message.component.html',
    styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { title: string; content: string; status: 'success' | 'error' }
  ) {}
}
