<div class="btn-col">
  <button
    *ngIf="params.onEdit"
    (click)="onEditClick()"
    mat-stroked-button
    class="mini-btn"
    color="primary"
  >
    <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
  </button>

  <button
    *ngIf="params.onDuplicate"
    (click)="onDuplicateClick()"
        mat-stroked-button
    class="mini-btn"
    color="primary"
  >
    <mat-icon fontSet="material-icons-outlined">content_copy</mat-icon>
  </button>

  <button
    *ngIf="params.onView"
    (click)="onViewClick()"
        mat-stroked-button
    class="mini-btn"
    color="primary"
  >
    <mat-icon fontSet="material-icons-outlined">visibility</mat-icon>
  </button>

  <button
    *ngIf="params.onDelete"
    (click)="onDeleteClick()"
        mat-stroked-button
    class="mini-btn"
    color="warn"
  >
    <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
  </button>

  <button
    *ngIf="params.onChangeAvailability"
    (click)="onChangeAvailabilityClick()"
        mat-stroked-button
    class="mini-btn"
    [color]="params.data.isActive ? 'warn' : 'accent'"
  >
    <mat-icon fontSet="material-icons-outlined">{{ params.data.isActive ? 'close' : 'check' }}</mat-icon>
  </button>

  <button
    *ngIf="params.getValue()?.onConfirmation"
    (click)="onConfirmationClick()"
        mat-stroked-button
    class="mini-btn"
    color="accent"
  >
    <mat-icon fontSet="material-icons-outlined">check_box</mat-icon>
  </button>
</div>
