import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../models/api-response-model';

export interface IBaseSearchParameters {
  sortColumn?: string,
  sortOrder?: 'asc' | 'desc',
  backUrl?: string,
}

export class BaseService<T = any> {
  protected domain: string;
  protected baseUrl: string;
  protected http: HttpClient;

  constructor(
    httpClient: HttpClient,
    serviceUrl: string,
  ) {
    this.domain =
      environment.baseUrl ?? location.origin.replace('portal', 'api');
    this.baseUrl = [this.domain, 'api', serviceUrl].join('/');
    this.http = httpClient;
  }

  entityExists(data: any): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/entityExists`, { name: data})
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  add(userData: any): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}`, userData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  get(filters: any | null = null): Observable<APIResponseModel<any[]>> {
    return this.http
      .get<APIResponseModel<any[]>>(
        `${this.baseUrl}`,
        filters ? { params: {...filters } } : { params: {} }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }

  getById(id: string): Observable<APIResponseModel<T>> {
    return this.http.get<APIResponseModel<T>>(`${this.baseUrl}/${id}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.httpErrorHandler(err, {});
      })
    );
  }

  update(id: string, data: any): Observable<APIResponseModel<any>> {
    return this.http
      .put<APIResponseModel<any>>(`${this.baseUrl}/${id}`, data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  delete(id: string): Observable<APIResponseModel<any>> {
    return this.http
      .delete<APIResponseModel<any>>(`${this.baseUrl}/${id}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  httpErrorHandler(
    err: HttpErrorResponse,
    data: any
  ): Observable<APIResponseModel<any>> {
    var response = new APIResponseModel<any>();
    response.isError = true;
    response.data = data;
    return of(response);
  }
}
